var loading = document.querySelector( '.mad__loading' );
var validateLoading = document.body.contains( loading );

var slideIndex = 0;
var slides = document.getElementsByClassName( 'mySlides' );
var countSlides = slides.length;

if ( validateLoading === true ) {
  showSlides();
  function showSlides() {
    var i;
    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = 'none';  
    }
    slideIndex++;
    if ( slideIndex > slides.length ) {
      slideIndex = slides.length;
    }

    slides[slideIndex-1].style.display = "block";
    setTimeout(showSlides, 1000);
  }
}

document.addEventListener('DOMContentLoaded', function() {
  if ( validateLoading === true ) {
    setInterval(() => {
      loading.classList.remove( 'mad__loading-show' );
    }, (countSlides + 1) + '000' );
  }
});