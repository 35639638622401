  
var logoHome = document.querySelector( '.mad__position' );
var logoOther = document.querySelector( '.mad__header__logo' );
var menuFull = document.querySelector( '.mad__menu-full' );
var titleHome = document.querySelector( '.mad__titlejs' );
var hamburguerHome = document.getElementById( 'mad__hamburguer-js' );
var validateHamburguerHome = document.body.contains( hamburguerHome );
var hamburguerOther = document.getElementById( 'mad__hamburguer-other-js' );
var validateHamburguerOther = document.body.contains( hamburguerOther );

if ( validateHamburguerHome === true ) {
    hamburguerHome.addEventListener( 'click', function( event ) {
        event.preventDefault();
        menuFull.classList.toggle( 'active' );
        this.classList.toggle( 'active' );
        logoHome.classList.toggle( 'active' );    
        titleHome.classList.toggle( 'active' );
    });
}

if ( validateHamburguerOther === true ) {
    hamburguerOther.addEventListener( 'click', function( event ) {
        event.preventDefault();
        menuFull.classList.toggle( 'active' );
        logoOther.classList.toggle( 'active' );
        if ( this.classList.contains( 'active', 'index' ) ) {
            this.classList.remove( 'active', 'index' );
        } else {
            this.classList.add( 'active', 'index' );
        }
    });
}
